import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="coming-soon-container">
      <div className="logo-container">
        <img src={logo} alt="Air Microservices Logo" className="logo" />
        <h4>Your partner in backend development excellence</h4>
        <h1>🚧 On the way 🛠️</h1>
      </div>
    </div>
  );
}

export default App;
